<script setup>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { storeToRefs } from "pinia";
import { router } from "@/router";
import { onMounted, watch } from "vue";
import {
  useAuthStore,
  useUsersStore,
  useNofiticationStore,
  useMachinesStore,
  saveBackend,
} from "@/stores";

const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);
let allUsers = [];
const usersStore = useUsersStore();
const { users } = storeToRefs(usersStore);
onMounted(() => {
  //   console.log(authUser?.value.isAdmin);
  if (authUser?.value.isAdmin) {
    usersStore.getAll();
  } else {
    router.push("/");
  }
});
// watch(users, (newUsers) => {
//   allUsers = newUsers;
//   if (allUsers.length > 0) {
//     const firstUserId = allUsers[0].id;
//     // console.log("İlk kullanıcının ID'si:", allUsers[2].id);
//   }
// });
import { ref } from "vue";
const index = ref(0);
const panelStates = ref([
  "Yeni Üye Kaydı",
  "Yeni Makina Kaydı",
  "Üyeye Makina Bağla",
  "Üye Ayarları",
  "Makina Ayarları",
]);

function alertMessage() {
  alert("Merhaba");
}
function onSave(values) {
  // console.log("Kayıt İşlemleri Başlatılıyor.");
  // console.log("Index:", index.value);
  switch (index.value) {
    case 1:
      // console.log("Makina Kaydı Gerçekleştiriliyor.");
      const { machineNote, machineID } = values;
      saveBackend.saveMachine(machineNote, machineID);
      break;
    default:
      break;
  }
}

function onUserSave(values) {
  // console.log("Üye Kaydı İşlemleri Başlatılıyor.");
  const { name, surname, email, password, passwordConfirm } = values;
  // console.log("değerler:", values);
  if (password !== passwordConfirm) {
    alert("Şifreler uyuşmuyor.");
    return;
  }
  // stringfy

  saveBackend.saveUser(values);
}
</script>

<template>
  <div class="configration">
    <div class="v-buttons">
      <button>Yeni Üye Kaydı</button>
      <!-- <button>Yeni Makina Kaydı</button>
      <button>Üyeye Makina Bağla</button>
      <button>Üye Ayarları</button>
      <button>Makina Ayarları</button> -->
    </div>
    <div class="settings-panel">
      <div class="state">
        <!-- buttonlara basıldığında indexi bir azaltsın -->
        <button :disabled="index == 0" @click="index -= 1"><<</button>
        <h2>{{ panelStates[index] }}</h2>
        <button :disabled="index == 0" @click="index += 1">>></button>
      </div>
      <div v-show="index == 0" class="settings-form">
        <Form @submit="onUserSave">
          <Field
            name="name"
            type="text"
            placeholder="Ad"
            class="form-control"
          />
          <Field
            name="surname"
            type="text"
            placeholder="Soyad"
            class="form-control"
          />
          <Field
            name="email"
            type="email"
            placeholder="E-posta"
            class="form-control"
          />
          <Field
            name="password"
            type="password"
            placeholder="Şifre"
            class="form-control"
          />
          <Field
            name="passwordConfirm"
            type="password"
            placeholder="Şifre Tekrar"
            class="form-control"
          />
          <button type="submit">Kaydet</button>
        </Form>
      </div>
      <div v-show="index == 1" class="settings-form">
        <Form @submit="onSave">
          <Field
            name="machineNote"
            type="text"
            placeholder="Makina Notu"
            class="form-control"
          />
          <Field
            name="machineID"
            type="text"
            placeholder="Makina ID"
            class="form-control"
          />
          <button>Kaydet</button>
        </Form>
      </div>
      <div v-show="index == 2" class="settings-form">
        <Form>
          <button @click="alertMessage">?</button>
          <Field
            name="username"
            type="text"
            placeholder="Üye E-posta"
            class="form-control"
          />
          <button @click="alertMessage">?</button>
          <Field
            name="machineId"
            type="text"
            placeholder="Makina ID"
            class="form-control"
          />
          <button type="submit">Bağla</button>
        </Form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.configration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  //align-items: center;
  //height: 100vh;
  .v-buttons {
    flex-direction: row;
  }
  button {
    margin: 2px 4px;
    cursor: pointer;
  }
  .settings-panel {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    width: 85%;
    height: 800px;
    background-color: #1f1f1f56;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #000000;
    .state {
      display: flex;
      flex-direction: row;
      ////space-between;
      justify-content: space-between;
      width: 98%;
      margin: 10px;
      button:disabled {
        background-color: #361c1c56;
        color: #ffffff;
        cursor: not-allowed;
      }
    }
    .settings-form {
      display: flex;
      //width: 98%;
      justify-content: space-between;
      align-items: center;
      //flex-direction: row;
      ////space-between;
      .form-control {
        // display: block;
        height: 50px;
        //width: 100px;
        background-color: rgba(255, 255, 255, 0.07);
        border-radius: 6px;
        padding: 0 10px;
        margin: 8px 8px;
        //align-items: center;
        // font-size: 14px;
        // font-weight: 300;
      }
      ::placeholder {
        color: #e5e5e5;
      }
    }
  }
}
</style>
