<template>
  <div class="form">
    <Form
      @submit="onLogin"
      :validation-schema="schema"
      v-slot="{ errors, isSubmitting }"
    >
      <h2>Sisteme Giriş Yapın.</h2>
      <label>E-mail</label>
      <Field
        name="username"
        type="text"
        class="form-control"
        placeholder="Kullanıcı Adı"
        :class="{ 'is-invalid': errors.username }"
      />
      <div class="invalid-feedback">{{ errors.username }}</div>
      <label>Şifre</label>
      <Field
        name="password"
        type="password"
        class="form-control"
        placeholder="Şifre"
        :class="{ 'is-invalid': errors.password }"
      />
      <div class="invalid-feedback">{{ errors.password }}</div>
      <button class="login-button" :disabled="isSubmitting">
        <span v-show="isSubmitting" class=""></span>
        Giriş Yap
      </button>
      <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">
        {{ errors.apiError }}
      </div>
    </Form>
  </div>
</template>

<style lang="scss">
.form {
  height: 460px;
  width: 400px;
  @media (max-width: 400px) {
    width: 90%;
    //height: 60%;
  }
  @media (max-height: 600px) {
    width: 50%;
    height: 100%;
    //height: 60%;
  }
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  border: 3px solid rgba(255, 255, 255, 0.1);
  padding: 20px 35px;
  * {
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
  }
  label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
  }
  .form-control {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
  }
  ::placeholder {
    color: #e5e5e5;
  }
  button {
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>

<script setup>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuthStore } from "@/stores";

const schema = Yup.object({
  username: Yup.string().required("Kullanıcı adı zorunludur."),
  password: Yup.string().required("Şifre zorunludur."),
});

function onLogin(values, { setErrors }) {
  // console.log("login");
  const authStore = useAuthStore();
  const { username, password } = values;
  return authStore.login(username, password).catch((error) => {
    setErrors({ apiError: error });
  });
}
</script>
