<template>
  <div class="container">
    <header>
      <nav class="nav-bar" v-show="authStore.user">
        <div class="logo">
          <img src="./assets/whicon.png" alt="Logo" />
        </div>
        <!-- <router-link to="/" active-class="active">Anasayfa</router-link> -->
        <!-- <router-link
          to="/machines"
          v-if="authStore.user && authStore.user.isAdmin"
          >Makinalar</router-link
        > -->
        <router-link
          to="/settings"
          active-class="active"
          v-if="authStore.user && authStore.user.isAdmin"
          >Ayarlar</router-link
        >
        <router-link
          to="/mymachines"
          active-class="active"
          v-if="authStore.user && !authStore.user.isAdmin"
          >Makinalarım</router-link
        >
        <a @click="authStore.logout()" class="nav-item nav-link">Çıkış Yap</a>
      </nav>
    </header>
    <div class="router-view-container">
      <router-view></router-view>
    </div>
  </div>
  <BackgroundAnimation />
</template>

<style lang="scss">
a {
  cursor: pointer;
}
body {
  overflow: hidden;
}
.container {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  //height: 100vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //height: 100vh;
  //width: 100%;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  //box-sizing: border-box;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0;
  .logo {
    margin: 5px 6px;

    img {
      height: 30px;
    }
  }
  nav {
    display: flex;
    justify-content: center;
    background: rgba(#27234d, 1);
    width: 100%;
    overflow: hidden;
    box-shadow: rgba(211, 188, 188, 0.24) 0px 5px 14px;

    a {
      display: block;
      color: rgb(170, 170, 170);
      text-decoration: none;
      padding: 10px;
      font-size: 1.1em;
      &:hover {
        background-color: #453f78;
      }
    }
  }
}
.home,
.scrollable-table,
.admin {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.home {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

table {
  border-collapse: collapse;
  border: 2px solid rgb(140 140 140);
  width: 85%;
}
thead {
  //font-size: 1.4em;
  background-color: #7d8abc4d;
}

th,
td {
  border: 1px solid #eb5b00;
  background-color: #3044634f;
  padding: 8px 10px;
}
@media (max-height: 500px) {
  .scrollable-table {
    font-size: 13px;
    letter-spacing: 1px;
    overflow-y: scroll;
    max-height: 160px; /* İhtiyacınıza göre ayarlayın */
  }
  /* Diğer özel stil tanımları buraya eklenebilir */
}
select:enabled {
  background-color: #3044634f;
  //border: 1px solid #eb5b00;
  padding: 10px 10px;
  //border-radius: 4px;
  option {
    background-color: #151e2b;
    //border: 1px solid #eb5b00;
    //padding: 6px;
    //border-radius: 4px;
  }
}
select:disabled {
  background-color: #000000;
  //border: 1px solid #eb5b00;
  padding: 10px 10px;
  border-radius: 4px;
}
button {
  background-color: #3044634f;
  border: 1px solid #eb5b00;
  padding: 6px;
  border-radius: 4px;
}
@media (max-width: 480px) {
  #app,
  body,
  header,
  nav,
  router-link {
    font-size: 14px; /* Font boyutunu düşür */
    padding: 0px 0px;
  }
  .home {
    font-size: 13px;
  }
  .logo {
    display: none;
  }
}

//   nav {
//     display: flex;
//     justify-content: center;
//     background: rgba(#27234d, 1);
//     width: 100%;
//     overflow: hidden;
//     box-shadow: rgba(211, 188, 188, 0.24) 0px 5px 14px;

//     a {
//       display: block;
//       color: rgb(189, 189, 189);
//       text-decoration: none;
//       padding: 10px;
//       font-size: 1.1em;
//       &:hover {
//         background-color: #453f78;
//       }
//     }
.active {
  color: white;
}
//   }
//   @media (max-width: 480px) {
//     #app,
//     body,
//     header,
//     nav,
//     router-link {
//       font-size: 16px; /* Font boyutunu düşür */
//       padding: 0px 0px;
//     }
//     .logo {
//       display: none;
//     }
//   }
// }
</style>

<script>
import BackgroundAnimation from "./components/BackgroundAnimation.vue";

export default {
  name: "App",
  components: {
    BackgroundAnimation,
  },
};
</script>
<script setup>
import { useAuthStore } from "@/stores";

const authStore = useAuthStore();
</script>
