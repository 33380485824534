import { defineStore } from "pinia";
import { fetchWrapper, router } from "@/router";

const baseUrl = `https://subank.info:8090/users`;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")),
    isAdmin: JSON.parse(localStorage.getItem("user.isAdmin")),
    returnUrl: null,
  }),
  actions: {
    async login(username, password) {
      const user = await fetchWrapper.post(`${baseUrl}/authenticate`, {
        username,
        password,
      });
      // console.log(user);
      // update pinia state
      this.user = user;
      this.isAdmin = user.isAdmin;
      // console.log(this.isAdmin);

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("isAdmin", JSON.stringify(user.isAdmin));

      // redirect to previous url or default to home page
      router.push(this.returnUrl || "/mymachines");
    },
    logout() {
      this.user = null;
      localStorage.removeItem("user");
      router.push("/login");
    },
  },
});
